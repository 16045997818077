import styled from "styled-components";
import fonts from "../../fonts/fonts.js";
import backgroundImage from "../../backgroundImage/pilulas_rehagro.png";
import backgroundImageMobile from "../../backgroundImage/pilulas_rehagro_2_mobile.png";

export const Wrapper = styled.div`
  /* background-image: url("Imagens/home_index.jpeg");
    display: table;
    width: 100%;
    height: 100vh;
    font-family: fonts.primary;
    padding: 100px 0;   
    background-position: 50% 50%;
    background-size: cover;
    display: flex; */
`;
export const Content = styled.div`
  overflow: hidden; /* para que não tenha rolagem se a imagem de fundo for maior que a tela */
  width: 100%;
  height: 100%;
  position: relative;

  display: table;
  width: 100%;
  height: 100vh;
  background-position: 50% 50%;
  background-size: cover;

  @media (max-width: 1080px) {
    background-image: url(${backgroundImageMobile});
  }
  @media (min-width: 1080px) {
    background-image: url(${backgroundImage});
  }
`;

export const TextContent = styled.div`
  margin-top: 30px;
  font-size: 25px;
  padding: 20px;
  width: 100%;
  color: #fff;
  font-weight: 700;
  font-family: "Meiryo UI", sans-serif;
`;

export const ContentWrapper = styled.div`
  background: rgba(0, 0, 0, 0.4);
  /* position:relative; */
  width: 100%;
  height: 100%;
  /* padding: 100px 0;    */
  /* background-position: 50% 50%; */
  /* background-size: cover; */
  /* display: flex; */
  display: flex;
  align-items: center;
  justify-content: center;
`;
