import { get_cooperativa, ativar_desativar } from '../../../Services/cooperativas'

//Função que traz os dados do banco de dados
export const get = (page) =>{
    return[(dispatch,getState)=>{
        
        const order = getState().cooperativa.order
        const th = 'cooperativa.des_cooperativa'
        const qtd_per_page = getState().cooperativa.qtd_per_page
        const pesquisa = getState().cooperativa.pesquisa || ''
        // const empresa_id = getState().auth.cooperativa.empresa_id
        
       
      
        get_cooperativa(page,qtd_per_page,order,th,pesquisa)
        .then(resp=>dispatch({type:'get_Cooperativa', payload:resp.data}))

    }]
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_pageCooperativa',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaCooperativa',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaCooperativa',
        payload:''
    },get(1)]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().cooperativa.asc
      
        if(asc === true){
            dispatch({type:'GetCooperativa_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetCooperativa_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]
    
    
}

export const handleCheeseChange = (event) =>{
    return[{
        type:'changeActiveCooperativa',
        payload:event.target.checked === true ? (1):(0),
        id_check:event.target.id
        
    },changeStateActive()]
    
}

export const changeStateActive = () =>{
    return (dispatch,getState) => {
        const id = getState().cooperativa.id_check
        const check =parseInt(getState().cooperativa.check)
        const data = {
            ativo:check,
            
        }
        ativar_desativar(id, data)
        // axios.put(`${URLPOSTTECNICO}/${id}`,{check:parseInt(check)})
        .then(resp => dispatch(get()))
        .catch(resp => dispatch(get()))
    }
}
    