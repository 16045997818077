import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./store/index";
import { Provider } from "react-redux";
import jwt from "jsonwebtoken";
import { baseURL } from "./baseURL/baseURL";
import { information } from "./Components/Services/auth";

const jwt_secret =
  "c531WazxLFVuYSejy5PvHSbWnfTSAxe6GOlf4xVXJFizlTsuRzstBXCYlh5rkUJn";

let token = localStorage.getItem("token");

if (token) {
  jwt.verify(token, jwt_secret, (err, decoded) => {
    if (err) {
      console.log(err);
      if (err.name !== "NotBeforeError") {
        localStorage.removeItem("token");
        token = null;
      }
    } else {
      if (decoded.iss !== baseURL + "/login") {
        localStorage.removeItem("token");
        token = null;
      }
    }
  });
}
// if(token){
//     jwt.verify(token, jwt_secret, function(err, decoded)  {
//         if(err){
//             localStorage.removeItem('token')
//             token = null;

//         }else{
//             if(decoded.iss !== baseURL+'/login'){
//                 localStorage.removeItem('token')
//                 token = null;
//             }
//         }

//       });
// }

const render = () => {
  ReactDOM.render(
    <React.Fragment>
      <Provider store={store}>
        <App />
      </Provider>
    </React.Fragment>,

    document.getElementById("root")
  );
};
if (token) {
  information()
    .then((res) => {
      store.dispatch({ type: "SET_LOGIN", payload: res.data });
    })
    .then((res) => {
      render();
    });
} else {
  render();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
