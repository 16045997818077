import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Style from '../../Style Page/style_page'
import Form from '../../Forms/form'
import Input from '../../Forms/input'
import Header from '../../Header/header'
import Sidebar from '../../Sidebar/sidebar'
import Select from '../../Forms/select'
import Footer from '../../Footer/footer'
import {Link} from 'react-router-dom'
import Alert from '../../Alerts/alert'
import { Content } from './styleLoading'
import ReactLoading from 'react-loading'
import { categoria_pilula,edit_pilula } from '../../Services/Pilula'
import { getCampanha, getSetor, postAgendamento } from '../../Services/agendamento'
import { editPilula, changeData, changePeso, changeCampanha, changeSetor  } from './Actions/actions'
import fonts from "../../../fonts/fonts.js";
import SelectComponent from 'react-select'

class agenda_pilula extends Component {

    state = {
        loading:false,
        categoriaPilula:[],
        campanhas:[],
        setores:[],
        setor:[],
        error:'',
        success: ''
    }

    clearErrors () {
        setTimeout(() => {
            this.setState({error : ''});
        }, 5000);
    }
 
    async componentDidMount(){
        const id = this.props.match.params.id
        await edit_pilula(id).then(resp=>(
            this.props.editPilula(
                resp.data[0]
            )

        ))
        .then(resp => (
            categoria_pilula(this.props.empresa_id)
            .then(resp=>(this.setState({categoriaPilula:resp.data})))

        ))
        .then(resp => (
            getCampanha(this.props.empresa_id)
            .then(resp => (this.setState({campanhas:resp.data})))
        ))
        .then(resp => (
            getSetor(this.props.empresa_id)
            .then(resp => (this.setState({setores:resp.data})))
        ))     
    }

    //Mapeando o objeto com as categorais da pílulas
    categoriaPilula(){
        const categoriaPilula = this.state.categoriaPilula || [];
        return categoriaPilula.map(desc=>(
            <option key={desc.id} value={desc.id}>{desc.nome}</option>
        ))

    }

    campanhas(){
        const campanhas = this.state.campanhas || [];
        return campanhas.map(desc=>(
            <option value={desc.id} key={desc.id}>{desc.nome}</option>
        ))
    }

    setores(){
        const setores = this.state.setores || [];
        return setores.map(desc=>(
            <option value={desc.id} key={desc.id}>{desc.des_setor}</option>
        ))
    }

    handleForm=(e)=>{
        e.preventDefault();
        this.setState({loading:true})
        const data = {
            pilula_id:this.props.pilula_id,
            campanha_id:this.props.campanha,
            usuario_id:this.props.usuario_id,
            data_agenda:this.props.data_agenda,
            peso:this.props.pontos || '',
            empresa_id:this.props.empresa_id,
            setor: this.state.setor.map((value) => value.value)
        }
        console.log(data);
        postAgendamento(data)
        .then(res =>{
            this.setState({loading:false})
            this.setState({loading:false, success: res.data.success})
            
            
        })
        .then(res => {
            setTimeout(() => {
                this.props.history.push('/pilulas');
            }, 3000);
        })
        .catch(e => {
            this.setState({ loading: false });
            if (e && e.response && e.response.data && e.response.data.error) {
                this.setState({ error: e.response.data.error });
            } else {
                this.setState({ error: "Erro interno" });
            }
        })
        this.clearErrors();
    }

    
    handleChangeSetor = (newValue: any, actionMeta: any) => {
        this.setState((state) => ({
            setor: newValue
        }))
    };

    renderSetores = () => {
        const optionsSetores = this.state.setores.map((value) => ({
            value: value.id,
            label: value.des_setor
        }))
        return (
            <>
                <label>Setor</label>      
                    <SelectComponent
                        value={this.setor}
                        isMulti
                        name="Setor"
                        options={optionsSetores}
                        closeMenuOnSelect={false}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={this.handleChangeSetor}
                    />
                    {this.state.setor ? this.state.setor.length <= 0 ? (

                        <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{
                                opacity: 0,
                                width: "100%",
                                height: 0,
                                position: "absolute"
                            }}
                            defaultValue={this.state.setor}
                            required={true}
                        />
                        ):''
                        : this.state.setor == null ? (
                            <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{
                                opacity: 0,
                                width: "100%",
                                height: 0,
                                position: "absolute"
                            }}
                            defaultValue={this.state.setor}
                            required={true}
                            />
                        ):''
                    }
            </>
        )
    }

    render() {
        return (
            <div className="wrapper">
                <Header />
                <Sidebar />
                <Style 
                    title="Agendamento de Pilulas" 
                    subtitle="Agendar Pílula"
                >
                    {this.state.loading === true ? (
                        <Content>
                            <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                        </Content>
                    
                    ):('')}
                    {this.state.error ? (
                        <div>
                            <Alert type='danger'>
                                {this.state.error}
                            </Alert>
                        </div>
                    ):('')}
                    {this.state.success ? (
                        <div>
                            <Alert type='success'>
                                {this.state.success}
                            </Alert>
                        </div>
                    ):('')}

                    <div style={{display: this.state.loading === true ? ('none'):('block')}}>

                        <Form onSubmit={this.handleForm}>
                            <div className="row">
                                <div className="col-md-4">
                                    <Input 
                                        disabled="disabled" 
                                        label="Título" 
                                        value={this.props.titulo} 
                                        onChange={this.props.changeTitulo} 
                                        icon="fa-assistive-listening-systems" 
                                        name="titulo" 
                                        type="text" 
                                        placeholder="Digite o título da pílula"
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Select 
                                        disabled="disabled" 
                                        value={`${this.props.tipo}`.toLowerCase()} 
                                        label="Tipo"
                                    >
                                        <option>Selecione um tipo</option>
                                        <option value="texto">Texto</option>
                                        <option value="audio">Aúdio</option>
                                        <option value="video">Vídeo</option>
                                    </Select>
                                </div>
                                <div className="col-md-4">
                                    <Select 
                                        disabled="disabled" 
                                        value={this.props.categoria} 
                                        label="Categoria"
                                    >
                                        <option>Selecione a categoria</option>
                                        {this.categoriaPilula()}
                                    </Select>
                                </div>
                            
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <Select 
                                        onChange={this.props.changeCampanha} 
                                        label="Campanha"
                                        required={true}
                                    >
                                        <option value=''>Selecione um tipo</option>
                                        {this.campanhas()}
                                    </Select>
                                </div>

                                <div className="col-md-4">
                                    {this.renderSetores()}
                                </div>
                            
                                <div className="col-md-4">
                                    <Input 
                                        label="Data" 
                                        onChange={this.props.changeData} 
                                        icon="fas fa-calendar-alt" 
                                        name="data" 
                                        type="date" 
                                        placeholder="Digite a data d agendamento"
                                        required={true}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input 
                                        label="Peso" 
                                        onChange={this.props.changePeso} 
                                        icon="fas fa-balance-scale" 
                                        name="peso" 
                                        type="number" 
                                        placeholder="Digite o peso da pílula"
                                        value={this.props.pontos}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                                
                            <br></br>
                            <div className="text-right">
                                <button type="submit" className="btn btn-primary" style={{fontFamily: fonts.primary, fontSize: "1rem"}}>Salvar</button>                           
                                <Link to="/pilulas" type="button" className="btn btn-secondary" style={{marginLeft:'10px'}}>Cancelar</Link>
                            </div>        
                        </Form>
                    </div>
                </Style>
                
                <Footer />
            </div>  
        )
    }
}
const mapStateToProps = state => ({
    titulo:state.pilula.titulo,
    tipo:state.pilula.tipo,
    categoriaPilula:state.pilula.categoriaPilula,
    categoria:state.pilula.categoria,
    nivel:state.pilula.nivel,
    empresa_id:state.auth.user.empresa_id,
    data_agenda:state.pilula.data_agenda,
    peso:state.pilula.peso,
    usuario_id:state.auth.user.id_usuario,
    pilula_id:state.pilula.pilula_id,
    campanha:state.pilula.campanha,
    setor:state.pilula.setor,
    pontos:state.pilula.pontos
})

const mapDispatchToProps = dispatch => bindActionCreators({
    editPilula, changeData, changePeso, changeCampanha, changeSetor

},dispatch)

export default connect(mapStateToProps,mapDispatchToProps)(agenda_pilula)
