import React, { Component } from 'react'
import Style from '../../Style Page/style_page'
import Form from '../../Forms/form'
import Input from '../../Forms/input'
import Select from '../../Forms/select'
import { Link } from 'react-router-dom'
import { register, getSetor, edit_user, put_user, getResourceUser, getCidade  } from '../../Services/user'
import Alert from '../../Alerts/alert'
import Header from '../../Header/header'
import Sidebar from '../../Sidebar/sidebar'
import Footer from '../../Footer/footer'
import ReactLoading from 'react-loading'
import { Content } from './styleLoading'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import fonts from "../../../fonts/fonts.js";

class User extends Component {
    state = {
        action:'',
        nome:'',
        setor_id:'',
        estado_id:'',
        cidade_id:'',
        cooperativa_id:'',
        regiao_id:'',
        perfil_id: '',
        perfil_status: '',
        regioes:[],
        setores:[],
        estados:[],
        cidades:[],
        cooperativas:[],
        perfis: [],
        loading:false,
        email:'',
        password:'',
        confirm_password:'',
        perfil:'',
        error:[],
        success:'',
        alert:'',
        errorConfirmPassword:'',
        cpf:'',
        idade: ''
    }

    componentDidMount () {
        const action = this.props.match.params.action
        this.setState({action:action})
        if(action === 'edit' || action === 'view'){
            edit_user(this.props.match.params.id)
            .then(resp => (
                this.setState({
                    nome:resp.data.nome, 
                    email:resp.data.email,
                    setor_id:resp.data.setor_id,
                    cpf:resp.data.cpf,
                    estado_id: resp.data.estado_id,
                    cidade_id: resp.data.cidade_id,
                    cooperativa_id: resp.data.cooperativa_id,
                    regiao_id: resp.data.regiao_id,
                    idade: resp.data.idade,
                    perfil_id: resp.data.id_perfil
                }),
                getCidade(resp.data.estado_id).then(desc => {this.setState({cidades:desc.data})})
            ))
            .then(resp => (getSetor(this.props.empresa_id)))
        }
        this.setState({email:'',password:'',confirm_password:'',perfil:'',error:[],success:'',alert:'',cpf:''})
        getResourceUser(this.props.empresa_id)
            .then(resp => {
                this.setState({
                    setores:resp.data.setor, 
                    estados: resp.data.estado, 
                    cooperativas: resp.data.cooperativa, 
                    regioes: resp.data.regiao,
                    perfis: resp.data.perfis
                
                })
            })
    }

    changeEmail = event => {
        const email = event.target.value
        this.setState({email:email})
    }

    changePassword = event => {
        const password = event.target.value
        this.setState({password:password})
    }

    changeCpf = event => {
        const cpf = event.target.value.replace('.','').replace('.','').replace('-','')
        this.setState({cpf:cpf})
    }

    changeNome = event => {
        const nome = event.target.value
        this.setState({nome:nome})
    }

    changeIdade = event => {
        const idade = event.target.value
        this.setState({idade:idade})
    }
    
    changeConfirmPassword = event => {
        const confirm_password = event.target.value
        this.setState({confirm_password:confirm_password})
    }

    handleSetores(){
        const setores = this.state.setores || []
        return setores.map(desc => (
            <option key={desc.id} value={parseInt(desc.id)}>{desc.des_setor}</option>
        ))
    }

    handleEstados(){
        const estados = this.state.estados || []
        return estados.map(desc => (
            <option key={desc.id} value={parseInt(desc.id)}>{desc.name}</option>
        ))
    }

    handleCidades(){
        const cidades = this.state.cidades || []
        return cidades.map(desc => (
            <option key={desc.id} value={parseInt(desc.id)}>{desc.name}</option>
        ))
    }

    handleRegioes(){
        const regioes = this.state.regioes || []
        return regioes.map(desc => (
            <option key={desc.id} value={parseInt(desc.id)}>{desc.name}</option>
        ))
    }

    handleCooperativas(){
        const cooperativas = this.state.cooperativas || []
        return cooperativas.map(desc => (
            <option key={desc.id} value={parseInt(desc.id)}>{desc.des_cooperativa}</option>
        ))
    }

    handlePerfis(){
        const perfis = this.state.perfis || []
        let administrador = 2;
        let aluno = 8;
        return perfis.map(desc => {
            if(desc.id == administrador || desc.id == aluno){
                return  <option key={desc.id} value={parseInt(desc.id)}>{desc.descricao}</option>
            } else {
                return ''
            }
        })
    }

    changeSetor = event => {
        const setor = event.target.value
        this.setState({setor_id:setor})
    }

    changeEstado = event => {
        const estado = event.target.value
        this.setState({estado_id:estado, cidade_id: ''})
        getCidade(estado)
            .then(resp => {this.setState({cidades:resp.data})})
    }

    changeCidade = event => {
        const cidade = event.target.value
        this.setState({cidade_id:cidade})
    }

    changeRegiao = event => {
        const regiao = event.target.value
        this.setState({regiao_id:regiao})
    }

    changeCooperativa = event => {
        const cooperativa = event.target.value
        this.setState({cooperativa_id:cooperativa})
    }

    changePerfil = event => {
        const perfil = event.target.value
        if(!perfil){
           return this.setState( {perfil_id: '', perfil_status: ''} )
        }

        const status = this.state.perfis.find( value => value.id == perfil);

        this.setState( {perfil_id: perfil, perfil_status: status.status} )
    }
    

    handleForm=(e)=>{
        e.preventDefault();
        this.setState({loading:true})
        const data = {
            email:this.state.email,
            password:this.state.password,
            setor_id:this.state.setor_id,
            confirm_password:this.state.confirm_password,
            nome:this.state.nome,
            cpf:this.state.cpf,
            usuario_id:this.props.id_user,
            cidade_id: this.state.cidade_id,
            cooperativa_id: this.state.cooperativa_id,
            regiao_id: this.state.regiao_id,
            idade: this.state.idade,
            perfil_id: this.state.perfil_id,
            perfil_status: this.state.perfil_status,
            estado_id: this.state.estado_id
        }

        if(!this.state.action){

            if(this.state.password !== this.state.confirm_password)
            {
                this.setState({loading:false,errorConfirmPassword: 'Erro ao cadastrar o usuário. As senhas não conferem!'})
                // return;
            }else{

                register(data)
                .then(resp=> {
                    this.setState({
                        loading:false,
                        alert:'',
                        errorConfirmPassword:'',
                        error:[],
                        success:resp.data.success,
                        email:'',
                        password:'',
                        perfil:'',
                        confirm_password:'',
                        nome:'',
                        cpf:'',
                        setor_id:'',
                        cidade_id: '',
                        cooperativa_id: '',
                        regiao_id: '',
                        idade: '',
                        perfil_id: '',
                        perfil_status: ''

                    })       
                })
                .then(resp => (setTimeout(()=>{
                    this.setState({success:''})
    
                },2000)))
                .catch(e => (
                    this.setState({loading:false,success:'',error: e.response.data.error,alert:e.response.data.message ? ("Os dados fornecidos são inválidos"):('')}
                    )
                )) 
            }
        }
        if(this.state.action === 'edit' || this.state.action === 'view'){
            put_user(data,this.props.match.params.id)
                .then(resp=> {
                    this.setState({loading:false,alert:'',errorConfirmPassword:'',error:[],success:resp.data.success,email:'',password:'',perfil:'',confirm_password:'',nome:'',cpf:'',setor_id:''})       
                })
                .then(resp => (setTimeout(()=>{
                    window.location.reload()
    
                },2000)))
                .catch(e => (
                    this.setState({loading:false,success:'',error: e.response.data.error,alert:e.response.data.message ? ("Os dados fornecidos são inválidos"):('')}
                    )
                )) 
        }

                     
    }
  
    render() {
        return (
            <div className="wrapper">

            <Sidebar />
            <Header />
            <Style title={this.state.action === 'view' ? ('Visualização de Usuário'):this.state.action === 'edit'? ('Edição de Usuário'):('Cadastro de Usuário')}>
                {this.state.loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {this.state.alert ? (
                    <Alert type="danger">
                        {this.state.alert}
                    </Alert>
                ):('')}
                {this.state.success ? (
                    <Alert type="success">
                        {this.state.success}
                    </Alert>
                ):('')}

                <div style={{display: this.state.loading === true ? ('none'):('block')}}>
                    <Form onSubmit={this.handleForm}>
                        <div className="row">
                            <div className={this.state.action === 'edit' || this.state.action === 'view' ?('col-md-6'):('col-md-4')}>
                                <Input 
                                    label="Nome" 
                                    value={this.state.nome} 
                                    onChange={this.changeNome} 
                                    icon="fas fa-envelope" 
                                    name="nome" type="text" 
                                    placeholder="Digite o nome do usuário" 
                                    required="true"
                                    disabled={this.state.action === 'view' ? (true):(false)} 
                                />
                                {this.state.error ? (<p style={{color:'red'}}>{this.state.error.nome}</p>):('')}
                            </div>
                            <div className={this.state.action === 'edit' || this.state.action === 'view' ?('col-md-6'):('col-md-4')}>
                                <Input 
                                    label="Email" 
                                    value={this.state.email} 
                                    onChange={this.changeEmail} 
                                    icon="fas fa-envelope" 
                                    name="email" 
                                    type="text" 
                                    placeholder="Digite o email do usuário" 
                                    required="true"
                                    disabled={this.state.action === 'view' ? (true):(false)} 
                                />
                                {this.state.error ? (<p style={{color:'red'}}>{this.state.error.email}</p>):('')}
                            </div>
                            <div className={this.state.action === 'edit' || this.state.action === 'view' ?('col-md-6'):('col-md-4')}>
                                <NumberFormat 
                                    disabled={this.state.action === 'view' ? (true):(false)} 
                                    allowEmptyFormatting={false} 
                                    icon="fa-address-card"  
                                    mask='' 
                                    placeholder="Digite o CPF do consultor" 
                                    label="CPF *"	
                                    customInput={Input} 
                                    value={this.state.cpf} 
                                    onChange={this.changeCpf}  
                                    format={"###.###.###-##"} 
                                />
                                {this.state.error ? (<p style={{color:'red'}}>{this.state.error.cpf}</p>):('')}
                            </div> 
                            <div className={this.state.action === 'edit' || this.state.action === 'view' ?('col-md-6'):('col-md-4')}>
                                <NumberFormat 
                                    disabled={this.state.action === 'view' ? (true):(false)} 
                                    allowNegative={false} 
                                    icon="fa-address-card"  
                                    placeholder="Digite a idade" 
                                    label="Idade *"	
                                    customInput={Input} 
                                    value={this.state.idade} 
                                    onChange={this.changeIdade}  
                                />
                                {this.state.error ? (<p style={{color:'red'}}>{this.state.error.idade}</p>):('')}
                            </div> 
                            <div className={this.state.action === 'edit' || this.state.action === 'view' ?('col-md-6'):('col-md-4')}>
                                <Select 
                                    label="Estado" 
                                    onChange={this.changeEstado} 
                                    value={this.state.estado_id}
                                    required="true"
                                    disabled={this.state.action === 'view' ? (true):(false)} 
                                >
                                    <option value="">Selecione um estado</option>
                                    {this.handleEstados()}

                                </Select>
                                {this.state.error ? (<p style={{color:'red'}}>{this.state.error.estado_id}</p>):('')}
                            </div> 
                            <div className={this.state.action === 'edit' || this.state.action === 'view' ?('col-md-6'):('col-md-4')}>
                                <Select 
                                    label="Cidade" 
                                    onChange={this.changeCidade} 
                                    value={this.state.cidade_id}
                                    required="true"
                                    disabled={this.state.action === 'view' ? (true):(false)} 
                                >
                                    <option value="">Selecione uma cidade</option>
                                    {this.handleCidades()}

                                </Select>
                                {this.state.error ? (<p style={{color:'red'}}>{this.state.error.cidade_id}</p>):('')}
                            </div> 
                            <div className={this.state.action === 'edit' || this.state.action === 'view' ?('col-md-6'):('col-md-4')}>
                                <Select 
                                    label="Região Macro" 
                                    onChange={this.changeRegiao} 
                                    value={this.state.regiao_id}
                                    required="true"
                                    disabled={this.state.action === 'view' ? (true):(false)} 
                                >
                                    <option value="">Selecione uma região</option>
                                    {this.handleRegioes()}

                                </Select>
                                {this.state.error ? (<p style={{color:'red'}}>{this.state.error.regiao_id}</p>):('')}
                            </div> 
                            <div className={this.state.action === 'edit' || this.state.action === 'view' ?('col-md-6'):('col-md-4')}>
                                <Select 
                                    label="Grupo" 
                                    required="true"
                                    onChange={this.changeCooperativa} 
                                    value={this.state.cooperativa_id}
                                    disabled={this.state.action === 'view' ? (true):(false)} 
                                >
                                    <option value="">Selecione um grupo</option>
                                    {this.handleCooperativas()}

                                </Select>
                                {this.state.error ? (<p style={{color:'red'}}>{this.state.error.cooperativa_id}</p>):('')}
                            </div>   
                       
                            <div className={this.state.action === 'edit' || this.state.action === 'view' ?('col-md-12'):('col-md-4')}>
                                <Select 
                                    label="Setor" 
                                    required="true"
                                    onChange={this.changeSetor} 
                                    value={this.state.setor_id}
                                    disabled={this.state.action === 'view' ? (true):(false)} 
                                >
                                    <option value="">Selecione um setor</option>
                                    {this.handleSetores()}

                                </Select>
                                {this.state.error ? (<p style={{color:'red'}}>{this.state.error.setor_id}</p>):('')}
                            </div>

                            <div className={this.state.action === 'edit' || this.state.action === 'view' ?('col-md-6'):('col-md-4')}>
                                <Select 
                                    label="Perfil" 
                                    onChange={this.changePerfil} 
                                    value={this.state.perfil_id}
                                    required={true}
                                    
                                    disabled={this.state.action === 'view' ? (true):(false)} 
                                >
                                    <option value="" >Selecione um Perfil</option>
                                    {this.handlePerfis()}

                                </Select>
                                {this.state.error ? (<p style={{color:'red'}}>{this.state.error.perfil_id}</p>):('')}
                            </div>
                            
                        </div>

                        <div className='row'>
                            {!this.state.action && (
                                <>
                                    <div className="col-md-6" style={{display: this.state.action === 'edit' || this.state.action === 'view' ? ('none'):('block')}}>
                                        <Input 
                                            label="Senha" 
                                            value={this.state.password} 
                                            onChange={this.changePassword} 
                                            icon="fas fa-lock" 
                                            name="password" 
                                            type="password" 
                                            placeholder="Digite a senha do usuário" 
                                            required="true"
                                        />
                                        {this.state.errorConfirmPassword ? (<p style={{color:'red'}}>{this.state.errorConfirmPassword}</p>):('')}
                                        {this.state.error ? (<p style={{color:'red'}}>{this.state.error.password}</p>):('')}
                                    </div>
                                    <div className="col-md-6" style={{display: this.state.action === 'edit' || this.state.action === 'view' ? ('none'):('block')}}>
                                        <Input 
                                            label="Confirme Senha" 
                                            value={this.state.confirm_password} 
                                            onChange={this.changeConfirmPassword} 
                                            icon="fas fa-lock" 
                                            name="confirm_password" 
                                            type="password" 
                                            placeholder="Confirme a senha do usuário" 
                                            required="true"
                                        />
                                        {this.state.errorConfirmPassword ? (<p style={{color:'red'}}>{this.state.errorConfirmPassword}</p>):('')}
                                    </div>
                                </>
                            )}
                        </div>
                        <br></br>
                        {this.state.action === 'view' ?
                            <div className="text-right">
                                <Link to="/users" type="button" className="btn btn-secondary" style={{marginLeft:'10px'}}>Voltar</Link>
                            </div>
                            :
                            <div className="text-right">
                                <button type="submit" className="btn btn-primary" style={{fontFamily: fonts.primary, fontSize: "1rem"}}>Salvar</button>                           
                                <Link to="/users" type="button" className="btn btn-secondary" style={{marginLeft:'10px'}}>Cancelar</Link>
                            </div> 
                        }
                        
                              
                    </Form>
                </div>   
            </Style>
            <Footer />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    
    id_user:state.auth.user.id_usuario,
    empresa_id:state.auth.user.empresa_id
      
});
export default connect(mapStateToProps,null)(User);

