const RelatorioReducer = (state = {}, actions)=>{
    switch(actions.type){
        
        case 'GetRelatorio_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetRelatorio_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_Relatorio':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaRelatorio':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaRelatorio':
            return{...state, pesquisa:actions.payload, data_inicial:actions.data_inicial, data_final:actions.data_final}

        case 'changeDataInicialRelatorio':
            return{...state, data_inicial:actions.payload}

        case 'changeDataFinalRelatorio':
            return{...state, data_final:actions.payload}
        
        case 'get_Relatorio':
            return{...state, relatorios:actions.payload}
         

        default:
            return state;      
    }
}
export default RelatorioReducer;