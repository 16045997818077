const AgendaPilulaReducer = (state = {}, actions)=>{
    switch(actions.type){
        
        case 'GetAgendaPilula_Desc':
            return{...state,desc:actions.desc,asc:actions.asc,order:actions.order,th:actions.th}

        case 'GetAgendaPilula_Asc':
            return{...state,desc:actions.desc,asc:actions.asc,order:actions.order,th:actions.th}
        
        case 'qtd_per_pageAgenda':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaAgenda':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaAgenda':
            return{...state, pesquisa:actions.payload}

        case 'get_AgendaPilula':
            return{...state, agendas_pilulas:actions.payload}

        case 'changeActive':
            return{...state, check:actions.payload, id_check:actions.id_check}

        case 'changeActiveAll':
            return{...state}

        case 'changeActiveNone':
            return{...state}
            
        default:
            return state;
    }
}
export default AgendaPilulaReducer;