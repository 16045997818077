import React, { Component } from 'react'
import Menu from '../Menu/menu'
import {connect} from 'react-redux'
import fonts from "../../fonts/fonts.js";
import styled from "styled-components";

class sidebar extends Component {
    formatName() {
        let aux = this.props.nome_usuario.split(" ");
        if (aux.length > 1) {
            return `${aux[0].substring(0, 1)}${aux[1].substring(0, 1)}`;
        } else {
            return `${aux[0].substring(0, 1)}`;
        }

    }
    render() {
        const style = localStorage.getItem('style') 
        this.formatName()
        return (
            <React.Fragment>
                {/* Main Sidebar Container */}
                <aside className={`main-sidebar elevation-4 sidebar-light-${style} elevation-4`}>
                    {/* Brand Logo */}
                    <a href="/index" className={`brand-link text-sm navbar-${style}`} style={{backgroundColor: '#20ae61'}}>
                        {/* <img src={this.props.logo} alt="" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} /> */}
                        <span className="brand-text font-weight-light" style={{color: 'white', fontWeight: 'bolder', fontSize: "1rem", fontFamily: fonts.primary, visibility: 'hidden'}}>{this.props.nome}</span>
                    </a>
                    {/* Sidebar */}
                    <div className="sidebar">
                        {/* Sidebar user panel (optional) */}
                        <div className="mt-3 pb-3 mb-3 d-flex" style={{whiteSpace: 'initial'}}>
                            <Image>
                                {this.formatName()}
                            </Image>
                            <div className="info ml-2" style={{fontFamily: fonts.primary, fontSize: "1rem", display:'flex', alignItems: 'center'}}>
                                <a href="#/" className="d-block">
                                    {this.props.nome_usuario}
                                </a>
                            </div>
                        </div>
                        {/* Sidebar Menu */}
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column text-sm nav-compact nav-child-indent nav-flat nav-legacy" data-widget="treeview" role="menu" data-accordion="false">
                                <Menu /> 
                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>

            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return{
        style:state.auth.user.style,
        nome:state.auth.user.nome, 
        nome_usuario:state.auth.user.nome_usuario,
        logo:state.auth.user.logo
    }
  
};

export const Image = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c0c0c0;
  z-index: 9;
  font-weight: bold;
  font-family: Meiryo UI;
`;

export default connect(mapStateToProps)(sidebar)
