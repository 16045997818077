import React, { Component } from 'react'
import chroma from 'chroma-js';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Form from '../../Forms/form'
import Input from '../../Forms/input'
import Select from '../../Forms/select'
import Header from '../../Header/header'
import Sidebar from '../../Sidebar/sidebar'
import Style from '../../Style Page/style_page'
import Footer from '../../Footer/footer'
import { post_cooperativa, put_cooperativa, view_cooperativa, getResourceCooperativa } from '../../Services/cooperativas'
import Alert from '../../Alerts/alert'
import ReactLoading from 'react-loading'
import { Content } from '../Cooperativas/styleLoading'
import { Link } from 'react-router-dom';
import fonts from "../../../fonts/fonts.js";

export default class cooperativas extends Component {
    state = {
        // color: colourOptions[0].value,
        nome_cooperativa: '',
        empresa_id: '',
        error: '',
        alert: '',
        success: '',
        action: '',
        display: 'none',
        loading: false,
        empresas: [],
    }

    componentDidMount() {
        const action = this.props.match.params.action
        this.setState({ action: action })

        if (action === 'edit' || action === 'view') {

            view_cooperativa(this.props.match.params.id)
                .then(resp => (

                    this.setState({
                        nome_cooperativa: resp.data.nome_cooperativa,
                        empresa_id: resp.data.empresa_id,
                    })
                ))
            // } else {
            //     this.setState({

            //         empresas: resp.data.empresas
            //     })

            // }
        }

        getResourceCooperativa()
            .then(resp => { this.setState({ empresas: resp.data.empresas }) })
    }

    handleEmpresas() {
        const empresas = this.state.empresas || []
        console.log(empresas)
        return empresas.map(desc => (
            <option key={desc.id} value={parseInt(desc.id)}>{desc.nome}</option>
        ))
    }


    handleForm = (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        const data = {
            nome_cooperativa: this.state.nome_cooperativa,
            empresa_id: this.state.empresa_id
        }

        if (!this.state.empresa_id) {
            this.setState({ loading: false, success: '', error: 'O campo empresa é obrigatório' })
        }
        else {
            if (!this.state.action) {
                post_cooperativa(data)
                    .then(resp => (

                        this.setState({ loading: false, success: resp.data.success, error: '' })
                    ))
                    .then(resp => (
                        setTimeout(
                            () => {
                                window.location.reload()
                            }, 2000
                        )
                    ))
                    .catch(e => (
                        this.setState({ loading: false, success: '', error: e.response.data.error })
                    ))

            } else {
                const id = this.props.match.params.id
                put_cooperativa(data, id)
                    .then(resp => { this.setState({ loading: false, success: resp.data.success, error: '' }) })
                    .then(resp => (
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000)))
                    .catch(e => (
                        this.setState({ loading: false, success: '', error: e.response.data.error })
                    ))
            }

        }




    }

    changeEmpresa = event => {
        const empresa = event.target.value
        this.setState({ empresa_id: empresa })
    }
    changeName = e => {
        const nome = e.target.value
        this.setState({ nome_cooperativa: nome })
    }



    render() {
        return (
            <div className="wrapper">
                <Header />
                <Sidebar />
                <Style title={this.state.action === 'view' ? ('Visualização de Grupo') : this.state.action === 'edit' ? ('Edição de Grupo') : ('Cadastro de Grupo')}>
                    {this.state.alert ? (
                        <Alert type="danger">
                            {this.state.alert}
                        </Alert>
                    ) : ('')}
                    {this.state.error ? (
                        <Alert type="danger">
                            {this.state.error}
                        </Alert>
                    ) : ('')}
                    {this.state.success ? (
                        <Alert type="success">
                            {this.state.success}
                        </Alert>
                    ) : ('')}
                    {this.state.loading === true ? (
                        <Content>
                            <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                        </Content>

                    ) : ('')}
                    <div style={{ display: this.state.loading === true ? ('none') : ('block') }}>
                        <Form onSubmit={this.handleForm}>
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        label="Nome Grupo"
                                        placeholder="Digite um nome para o grupo"
                                        type="text"
                                        required={true}
                                        icon="fas fa-briefcase"
                                        value={this.state.nome_cooperativa}
                                        onChange={this.changeName}
                                    />
                                </div>
                                <div className={this.state.action === 'edit' || this.state.action === 'view' ? ('col-md-6') : ('col-md-4')} >
                                    <Select
                                        label="Empresa"
                                        onChange={this.changeEmpresa}
                                        value={this.state.empresa_id}
                                        required="true"
                                        disabled={this.state.action === 'view' ? (true) : (false)}
                                    >
                                        <option value="">Selecione uma empresa</option>
                                        {this.handleEmpresas()}

                                    </Select>
                                    {this.state.error ? (<p style={{ color: 'red' }}>{this.state.error.empresa_id}</p>) : ('')}
                                </div>



                            </div>
                            <div className="text-right mt-5">
                                <button className="btn btn-primary" type="submit" style={{fontFamily: fonts.primary, fontSize: "1rem"}}>Salvar</button>
                                <Link to="/cooperativas" type="button" className="btn btn-secondary" style={{ marginLeft: '10px' }}>Cancelar</Link>
                            </div>

                        </Form>
                    </div>
                </Style>
                <Footer />

            </div>
        )
    }
}


