export const colourOptions = [
    { value: '#00B8D9', label: 'Ocean', color: '#00B8D9', isFixed: true },
    { value: '#0052CC', label: 'Blue', color: '#0052CC'},
    { value: '#5243AA', label: 'Purple', color: '#5243AA' },
    { value: '#FF5630', label: 'Red', color: '#FF5630', isFixed: true },
    { value: '#FF8B00', label: 'Orange', color: '#FF8B00' },
    { value: '#FFC400', label: 'Yellow', color: '#FFC400' },
    { value: '#36B37E', label: 'Green', color: '#36B37E' },
    { value: '#00875A', label: 'Forest', color: '#00875A' },
    { value: '#253858', label: 'Slate', color: '#253858' },
    { value: '#666666', label: 'Silver', color: '#666666' },
  ];