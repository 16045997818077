import { app } from './Api'


const getSetor = async (empresa_id) => {
    return app.get(`setor?empresa_id=${empresa_id}`)
}

const getCidade = async (estado_id) => {
    return app.get(`cidade?estado_id=${estado_id}`)
}

const getResourceCooperativa = async () => {
    return app.get(`get_resource_cooperativa`)
}

const register = async (data) => {
    return app.post(`register`,data)
}

const get_cooperativa = async (page,qtd,order,th,pesquisa) => {
    return app.get(`cooperativa?page=${page}&qtd=${qtd}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const ativar_desativar = async (id,data) => {
    return app.put(`change_status_cooperativa/${id}`,data)
}

// const delete_user = async (id) => {
//     return app.delete(`user/${id}`)
// }

// const edit_user = async (id) => {
//     return app.get(`user/${id}`)
// }

const view_cooperativa = async (id) => {
    return app.get(`cooperativa/${id}`)
}

const put_cooperativa = async (data,id) => {
    return app.patch(`cooperativa/${id}`,data)
}

const post_cooperativa = async (data) => {
    return app.post('cooperativa',data)
}

export { getSetor, register, get_cooperativa, ativar_desativar, view_cooperativa, post_cooperativa, put_cooperativa, getResourceCooperativa, getCidade }