import React, { Component } from 'react'
import chroma from 'chroma-js';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Form from '../../Forms/form'
import Input from '../../Forms/input'
import Select from 'react-select'
import Header from '../../Header/header'
import Sidebar from '../../Sidebar/sidebar'
import Style from '../../Style Page/style_page'
import Footer from '../../Footer/footer'
import { colourOptions } from './docs/data'
import { post_categoria, put_categoria, view_categoria } from '../../Services/categoria'
import Alert from '../../Alerts/alert'
import ReactLoading from 'react-loading'
import { Content } from '../Users/styleLoading'
import fonts from "../../../fonts/fonts.js";

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
  
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
      };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };

export default class categorias extends Component {
    state = {
        color:colourOptions[0].value,
        nome_categoria:'',
        error:'',
        alert:'',
        success:'',
        action:'', 
        display:'none',
        loading:false,
        colours:''
    }

    componentDidMount(){
        const action = this.props.match.params.action
        this.setState({action:action})
        
        if(action === 'edit' || action === 'view'){
            
            view_categoria(this.props.match.params.id)
            .then(resp => (
                
                this.setState({
                    nome_categoria:resp.data.nome, 
                    color:resp.data.cor,
                    colours:colourOptions.filter(file => file.value === resp.data.cor)
                })
            ))
        }else{
            this.setState({

                colours:colourOptions
            })

        }
    }

    handleObject = () => {
        
        for(var i=0; i<colourOptions.length; i++) {
            if(colourOptions[i].value === this.state.color) {
                this.setState({colours:[colourOptions[i]]}) 
            }
        }
    }

    handleForm = (e) => {
        e.preventDefault();
        this.setState({loading:true})
        const data = {
            name_category: this.state.nome_categoria,
            color: this.state.color
        }
        

        if(!this.state.action){
            post_categoria(data)
            .then(resp => (
                
                this.setState({loading:false,success:resp.data.success,error:''})
            ))
            .then(resp => (
                setTimeout(
                    ()=>{
                        window.location.reload()
                    },2000
                )
            ))
            .catch(e => (
                this.setState({loading:false,success:'',error:e.response.data.error})
            ))
            
        }else{
            const id = this.props.match.params.id
            put_categoria(data,id)
            .then(resp => {this.setState({loading:false,success:resp.data.success,error:''})})
            .then(resp => (
                setTimeout(()=>{
                    window.location.reload()
                },2000)))
            .catch(e => (
                this.setState({loading:false,success:'',error:e.response.data.error})
            ))
        }

    }

    changeColor = e => {
        const color = e.value
        this.setState({color:color})
    }

    changeName = e => {
        const nome = e.target.value
        this.setState({nome_categoria:nome})
    }
    render() {
        return (
            <div className="wrapper">
                <Header />
                <Sidebar />
                <Style title={this.state.action === 'view' ? ('Visualização de Categoria'):this.state.action === 'edit'? ('Edição de Categoria'):('Cadstro de Categoria')}>
                {this.state.alert ? (
                        <Alert type="danger" style={{fontFamily: fonts.primary, fontSize: "1rem"}}>
                            {this.state.alert}
                        </Alert>
                    ):('') }
                    {this.state.error ? (
                        <Alert type="danger" style={{fontFamily: fonts.primary, fontSize: "1rem"}}>
                            {this.state.error}
                        </Alert>
                    ):('') }
                    {this.state.success ? (
                        <Alert type="success" style={{fontFamily: fonts.primary, fontSize: "1rem"}}>
                            {this.state.success}
                        </Alert>
                    ):('') }
                    {this.state.loading === true ? (
                        <Content>
                            <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                        </Content>
                        
                    ):('')}
                    <div style={{display: this.state.loading === true ? ('none'):('block')}}>
                        <Form onSubmit={this.handleForm}>
                            <div className="row">
                                <div className="col-md-6">
                                    <Input 
                                        label="Nome Categoria"
                                        placeholder="Digite um nome para a categoria"
                                        type="text"
                                        required={true}
                                        icon="fas fa-briefcase"
                                        value={this.state.nome_categoria}
                                        onChange={this.changeName}
                                        style={{fontFamily: fonts.primary, fontSize: "1rem"}}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label style={{fontFamily: fonts.primary, fontSize: "1rem"}}>Cor</label>
                                    {this.state.colours ? (

                                        <Select 
                                            defaultValue={this.state.colours[0]}
                                            label="Single select"
                                            options={colourOptions}
                                            styles={colourStyles}
                                            onChange={this.changeColor}
                                            style={{fontFamily: fonts.primary, fontSize: "1rem"}}
                                        />
                                    ):('')}
                                </div>

                            

                            </div>
                            <div className="text-right mt-5">
                                <button className="btn btn-primary" type="submit" style={{fontFamily: fonts.primary, fontSize: "1rem"}}>Salvar</button>

                            </div>

                        </Form>
                    </div>
                </Style>
                <Footer />
                
            </div>
        )
    }
}


