import { get_agendamento, ativar_desativar, ativar_all, desativar_all } from '../../../Services/agendamento'

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_pageAgenda',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaAgenda',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaAgenda',
        payload:''
    },get(1)]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().agenda_pilula.asc
      
        if(asc === true){
            dispatch({type:'GetAgendaPilula_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetAgendaPilula_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]
    
    
}

//Função que traz os dados do banco de dados
export const get = (page) =>{
    return[(dispatch,getState)=>{
        
        const order = getState().agenda_pilula.order
        const th = getState().agenda_pilula.th
        const qtd_per_page = getState().agenda_pilula.qtd_per_page
        const pesquisa = getState().agenda_pilula.pesquisa || ''
        const empresa_id = getState().auth.user.empresa_id
        
       
      
        get_agendamento(page,qtd_per_page,order,th,pesquisa,empresa_id)
        .then(resp=>dispatch({type:'get_AgendaPilula', payload:resp.data}))

    }]
}

export const handleCheeseChange = (event) =>{
    return[{
        type:'changeActive',
        payload:event.target.checked === true ? (1):(0),
        id_check:event.target.id
        
    },changeStateActive()]
    
}

export const changeStateActive = () =>{
    return (dispatch,getState) => {
        const id = getState().agenda_pilula.id_check
        const check =parseInt(getState().agenda_pilula.check)
        const action = 'ativar_desativar'
        const data = {
            ativo:check,
            action:action
        }
        ativar_desativar(id, data)
        // axios.put(`${URLPOSTTECNICO}/${id}`,{check:parseInt(check)})
        .then(resp => dispatch(get(getState().agenda_pilula.agendas_pilulas.current_page)))
        .catch(resp => dispatch(get(getState().agenda_pilula.agendas_pilulas.current_page)))
    }
}


export const handleChangeStateActivateAll = () =>{
    return[{
        type:'changeActiveAll',   
    },changeStateActivateAll()]
}

export const changeStateActivateAll = () =>{
    // console.log("teste1!")
    return (dispatch,getState) => {
        // console.log("teste2!")
        const order = getState().agenda_pilula.order
        const th = getState().agenda_pilula.th
        const qtd_per_page = getState().agenda_pilula.qtd_per_page
        const pesquisa = getState().agenda_pilula.pesquisa || ''
        const empresa_id = getState().auth.user.empresa_id
        ativar_all(1,qtd_per_page,order,th,pesquisa,empresa_id)

        .then(resp => dispatch(get()))
        // .then(resp => console.log("testeGet"))
        .catch(resp => dispatch(get()))
    }
     
}


export const handleChangeStateDeactivateAll = (event) =>{
    return[{
        type:'changeActiveNone',
        
    },changeStateDeactivateAll()]
}

export const changeStateDeactivateAll = (page) =>{
    // console.log("teste1!")
    return (dispatch,getState) => {
        // console.log("teste2!")
        const order = getState().agenda_pilula.order
        const th = getState().agenda_pilula.th
        const qtd_per_page = getState().agenda_pilula.qtd_per_page
        const pesquisa = getState().agenda_pilula.pesquisa || ''
        const empresa_id = getState().auth.user.empresa_id
    
    desativar_all(page,qtd_per_page,order,th,pesquisa,empresa_id)
    .then(resp => dispatch(get()))
    .catch(resp => dispatch(get()))
    }
}