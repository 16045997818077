import React, { Component } from "react";
import { connect } from "react-redux";
import { Wrapper } from "./styles";
import fonts from "../../fonts/fonts.js";
import { authentication } from "../Services/auth";
import Alert from "../Alerts/alert";
import { rememberPass } from "../../baseURL/baseURL";
// import backgroundImage from "../../backgroundImage/image_login.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "", error: "", sucesso: "" };
  }

  handleForm = (e) => {
    e.preventDefault();
    const data = { email: this.state.email, password: this.state.password };
    authentication(data)
      .then((res) => {
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("style", res.data.user.style);
        this.props.setLogin(res.data.user);

        this.props.history.push("/index");
      })
      .catch((e) => {
        this.setState({
          error: e.response.data.error,
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 3000);
      });
  };

  handleInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  handleClickOpenForgotPassword = () => {
    window.location.href = rememberPass;
  };

  render() {
    const error = this.state.error;
    const sucesso = this.state.sucesso;
    return (
      <React.Fragment>
        <Wrapper>
          <div className="login-box">
            {/* /.login-logo */}
            <div
              className="card"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <LogoStyle>
                                    <img src="Imagens/logo.webp" alt="Logo Cooxupé" width="130px" className="d-block" />
                                    <img src="http://191.252.200.82:8000/storage/imagens/rehagro.png" width="130px" alt="Logo Rehagro" className="d-block"  />

                                </LogoStyle> */}
              <div className="card-body login-card-body">
                {error.errors ? (
                  <p
                    className="errors"
                    style={{ color: "#DC143C", fontFamily: fonts.primary }}
                  >
                    {error.errors}
                  </p>
                ) : (
                  sucesso
                )}
                <i
                  style={{ marginLeft: "125px" }}
                  className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"
                ></i>
                <h5
                  className="login-box-msg"
                  style={{
                    fontFamily: fonts.primary,
                    fontSize: "1.3rem",
                    fontWeight: "bold",
                  }}
                >
                  Faça login na sua conta
                </h5>
                <p
                  className="login-box-msg"
                  style={{ fontFamily: fonts.primary, fontSize: "1.1rem" }}
                >
                  Entre com suas credenciais
                </p>
                {this.state.error ? (
                  <div>
                    <Alert
                      type="danger"
                      hidden="true"
                      style={{ fontFamily: fonts.primary }}
                    >
                      {this.state.error}
                    </Alert>
                  </div>
                ) : (
                  ""
                )}
                <form onSubmit={this.handleForm} style={{}}>
                  <div className="input-group mb-3">
                    <input
                      onChange={this.handleInput}
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="E-mail"
                      style={{ fontFamily: fonts.primary, fontSize: "1.1rem" }}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fa fa-envelope" />
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input
                      onChange={this.handleInput}
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Senha"
                      style={{ fontFamily: fonts.primary, fontSize: "1.1rem" }}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fa fa-lock" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8 d-flex align-items-center">
                      <span
                        style={{
                          fontFamily: fonts.primary,
                          fontSize: "0.90rem",
                          cursor: "pointer",
                        }}
                        onClick={this.handleClickOpenForgotPassword}
                      >
                        Esqueci a senha
                      </span>
                    </div>

                    <div className="col-4" style={{ width: "28vw" }}>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        style={{
                          fontFamily: fonts.primary,
                          fontSize: "1.1rem",
                        }}
                      >
                        Entrar
                      </button>
                    </div>
                  </div>
                </form>
                <div className="social-auth-links text-center mb-3"></div>
              </div>
              {/* /.login-card-body */}
            </div>
          </div>
        </Wrapper>
        {/* /.login-box */}
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (user) => dispatch({ type: "SET_LOGIN", payload: user }),
  };
};
export default connect(null, mapDispatchToProps)(Login);
