import { app } from './Api'

const post_categoria = async (data) => {
    return app.post('categoria',data)
}

const put_categoria = async (data,id) => {
   return  app.put(`categoria/${id}`,data)
}

const get_categoria = async (page,qtd,order,th,pesquisa,empresa_id) => {
    return app.get(`categoria?page=${page}&order=${order}&qtd=${qtd}&th=${th}&empresa_id=${empresa_id}&pesquisa=${pesquisa}&action=list`)
}

const view_categoria = async (id) => {
    return app.get(`categoria/${id}`)
}

const ativar_desativar = async (id,data) => {
    return app.put(`change_status_categoria/${id}`,data)
}

const delete_categoria = async (id) => {
    return app.delete(`categoria/${id}`)
}

export { post_categoria, put_categoria, get_categoria, view_categoria, ativar_desativar, delete_categoria }