import React, { Component } from 'react'
import fonts from "../../fonts/fonts.js";

export default class textArea extends Component {
    render() {
        return (
            <React.Fragment>
                <label style={{fontFamily: fonts.primary, fontSize: "1rem"}}>{this.props.label}</label>
                <textarea 
                    onChange={this.props.onChange} 
                    className="textarea" 
                    placeholder={this.props.placeholder} 
                    style={{width: '100%', height: 200, fontSize: 14, lineHeight: 18, border: '1px solid #dddddd', fontFamily: fonts.primary, padding: 10}} 
                    value={this.props.value}
                    id={this.props.id}
                    disabled={this.props.disabled}
                    // value={this.props.value} 
                />      
            </React.Fragment>
        )
    }
}
