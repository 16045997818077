import { get_categoria, ativar_desativar } from '../../../Services/categoria'

//Função que traz os dados do banco de dados
export const get = (page) =>{
    return[(dispatch,getState)=>{
        
        const order = getState().categoria.order
        const th = getState().categoria.th
        const qtd_per_page = getState().categoria.qtd_per_page
        const pesquisa = getState().categoria.pesquisa || ''
        const empresa_id = getState().auth.user.empresa_id
        
       
      
        get_categoria(page,qtd_per_page,order,th,pesquisa,empresa_id)
        .then(resp=>dispatch({type:'get_Categoria', payload:resp.data}))

    }]
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_pageCategoria',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaCategoria',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaCategoria',
        payload:''
    },get(1)]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().categoria.asc
      
        if(asc === true){
            dispatch({type:'GetCategoria_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetCategoria_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]
    
    
}

export const handleCheeseChange = (event) =>{
    return[{
        type:'changeActiveCategoria',
        payload:event.target.checked === true ? (1):(0),
        id_check:event.target.id
        
    },changeStateActive()]
    
}

export const changeStateActive = () =>{
    return (dispatch,getState) => {
        const id = getState().categoria.id_check
        const check =parseInt(getState().categoria.check)
        const data = {
            ativo:check,
            
        }
        ativar_desativar(id, data)
        .then(resp => dispatch(get()))
        .catch(resp => dispatch(get()))
    }
}