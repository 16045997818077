import { app } from './Api'


const getSetor = async (empresa_id) => {
    return app.get(`setor?empresa_id=${empresa_id}`)
}

const getCidade = async (estado_id) => {
    return app.get(`cidade?estado_id=${estado_id}`)
}

const getResourceUser = async (empresa_id) => {
    return app.get(`get_resource_user?empresa_id=${empresa_id}`)
}

const register = async (data) => {
    return app.post(`register`,data)
}

const get_user = async (page,qtd,order,th,pesquisa,empresa_id) => {
    return app.get(`user?page=${page}&qtd=${qtd}&order=${order}&th=${th}&pesquisa=${pesquisa}&empresa_id=${empresa_id}`)
}

const ativar_desativar = async (id,data) => {
    return app.put(`change_status_user/${id}`,data)
}

const delete_user = async (id) => {
    return app.delete(`user/${id}`)
}

const edit_user = async (id) => {
    return app.get(`user/${id}`)
}

const put_user = async (data,id) => {
    return app.patch(`user/${id}`,data)
}

export { getSetor, register, get_user, ativar_desativar, delete_user, edit_user, put_user, getResourceUser, getCidade }