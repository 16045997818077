import { app } from './Api'

const getCampanha = async (empresa_id) => {
    return app.get(`campanha?empresa_id=${empresa_id}`)

}

const getSetor = async (empresa_id) => {
    return app.get(`setor?empresa_id=${empresa_id}`)
}

const postAgendamento = async (data) => {
    return app.post('agenda_pilula',data)
}

const get_agendamento = async (page,qtd,order,th,pesquisa,empresa_id) => {
    return app.get(`agenda_pilula?page=${page}&qtd=${qtd}&order=${order}&th=${th}&pesquisa=${pesquisa}&empresa_id=${empresa_id}`)
}

const ativar_desativar = async (id,data) => {
    return app.put(`agenda_pilula/${id}`,data)
}

const ativar_all = async (page,qtd,order,th,pesquisa,empresa_id) => {
    return app.put(`agenda_pilula/ativar_all?page=${page}&qtd=${qtd}&order=${order}&th=${th}&pesquisa=${pesquisa}&empresa_id=${empresa_id}`)
}

const desativar_all = async (page,qtd,order,th,pesquisa,empresa_id) => {
    return app.put(`agenda_pilula/desativar_all?page=${page}&qtd=${qtd}&order=${order}&th=${th}&pesquisa=${pesquisa}&empresa_id=${empresa_id}`)
}

export { getCampanha, getSetor, postAgendamento, get_agendamento, ativar_desativar, ativar_all, desativar_all }