const PilulaReducer = (state = {}, actions)=>{
    switch(actions.type){
        case 'changeTituloPilula':
            return{...state, titulo:actions.payload}

        case 'changeTituloNotificacao':
            return{...state, tituloNotificacao:actions.payload}

        case 'changeMsgNotificacao':
            return{...state,msgNotificacao:actions.payload}

        case 'changeTipo':
            return{...state, tipo:actions.payload}

        case 'changeCategoria':
            return{...state, categoria:actions.payload}

        case 'changeNivel':
            return{...state, nivel:actions.payload}

        case 'get_Pilula':
            return{...state, pilulas:actions.payload}

        case 'changePontos':
            return{...state, pontos: actions.payload}

        case 'changePontosVisualizacao':
            return{...state, pontosVisualizacao: actions.payload}

        case 'changeTexto':
            return{...state, texto:actions.payload }

        case 'changePergunta':
            return{...state, pergunta:actions.payload}

        case 'changeRespostaA':
            return{...state, respostaA:actions.payload}

        case 'changeRespostaB':
            return{...state, respostaB:actions.payload}

        case 'changeRespostaC':
            return{...state, respostaC:actions.payload}

        case 'changeRespostaD':
            return{...state, respostaD:actions.payload}

        case 'changeRespostaCorreta':
            return{...state,respostaCorreta:actions.payload}

        case 'changeFeedBackCorreto':
            return{...state, feedBackCorreto:actions.payload}

        case 'changeFeedBackErrado':
            return{...state, feedBackErrado:actions.payload}

        case 'changeImageIcone':
            return{...state, imageIcone:actions.payload}

        case 'changeImageFundo':
            return{...state,imageFundo:actions.payload}

        case 'getTexto':
            return{...state,texto:actions.payload}

        case 'GetPilula_Desc':
            return{...state,desc:actions.desc,asc:actions.asc,order:actions.order,th:actions.th}

        case 'GetPilula_Asc':
            return{...state,desc:actions.desc,asc:actions.asc,order:actions.order,th:actions.th}

        case 'clearList':
            return{...state,pilulas:actions.payload}

        case 'EditView':
            return{...state, 
                pilula_id:actions.pilula_id,
                categoria:actions.categoria,
                titulo:actions.titulo,
                tituloNotificacao:actions.tituloNotificacao,
                msgNotificacao:actions.msgNotificacao,
                push_large_icon:actions.push_large_icon,
                push_big_picture:actions.push_big_picture,
                nivel:actions.nivel,
                pontos:actions.pontos,
                pontosVisualizacao:actions.pontosVisualizacao,
                texto:actions.texto,
                video:actions.video,
                audio:actions.audio,
                pergunta:actions.pergunta,
                respostaA:actions.respostaA,
                respostaB:actions.respostaB,
                respostaC:actions.respostaC,
                respostaD:actions.respostaD,
                respostaCorreta:actions.respostaCorreta,
                feedBackCorreto:actions.feedBackCorreto,
                feedBackErrado:actions.feedBackErrado,
                tipo:actions.tipo
            }
        
        case 'Clear':
            return{...state,
                pilula_id:actions.pilula_id,
                categoria:actions.categoria,
                titulo:actions.titulo,
                tituloNotificacao:actions.tituloNotificacao,
                msgNotificacao:actions.msgNotificacao,
                push_large_icon:actions.push_large_icon,
                push_big_picture:actions.push_big_picture,
                nivel:actions.nivel,
                pontos:actions.pontos,
                pontosVisualizacao:actions.pontosVisualizacao,
                texto:actions.texto,
                video:actions.video,
                audio:actions.audio,
                pergunta:actions.pergunta,
                respostaA:actions.respostaA,
                respostaB:actions.respostaB,
                respostaC:actions.respostaC,
                respostaD:actions.respostaD,
                respostaCorreta:actions.respostaCorreta,
                feedBackCorreto:actions.feedBackCorreto,
                feedBackErrado:actions.feedBackErrado,
                tipo:actions.tipo
            
            }
        case 'Get_Categoria':
            return{...state, categorias:actions.payload}

        case 'changeDataAgenda':
            return{...state, data_agenda:actions.payload}

        case 'changePeso':
            return{...state, peso:parseInt(actions.payload)}

        case 'changeCampanha':
            return{...state, campanha:parseInt(actions.payload)}

        case 'changeSetor':
            return{...state, setor:parseInt(actions.payload)}

        case 'changeVideo':
            return{...state, video:actions.payload}
        
        case 'qtd_per_page':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisa':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisa':
            return{...state, pesquisa:actions.payload}
        default:
            return state;
    }
}
export default PilulaReducer;