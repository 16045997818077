import React, { Component } from 'react'
import Header from '../../Header/header'
import Sidebar from '../../Sidebar/sidebar'
import Footer from '../../Footer/footer'
import Table from '../../Table/table'
import Thead from '../../Table/thead'
import Tbody from '../../Table/tbody'
import Tfoot from '../../Table/tfoot'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, order, change_qtd_per_page, changePesquisa, clearPesquisa, handleCheeseChange, handleChangeStateActivateAll, handleChangeStateDeactivateAll } from './Actions/actions'
import { pagination } from '../../Pagination/paginations'
import { Link } from 'react-router-dom'
import Pagination from '../../Table/pagination'
import Cabecalho from '../../Table/cabecalho'
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import StylePage from '../../Style Page/style_page'
import fonts from "../../../fonts/fonts.js";
import ReactLoading from 'react-loading'
import { Content } from '../Users/styleLoading'

import { ativar_all, desativar_all } from '../../Services/agendamento'
// import { BASE_URL } from '../../../baseURL/baseURL'

class grid_agenda_pilula extends Component {
    state = { cheeseIsReady: false, check: 0, loading: false }


    componentDidMount() {
        this.props.get(1)
    }

    activateAll(e) {
        e.preventDefault();
        if (window.confirm("Deseja realmente ativar todos os registros selecionados?")) {
            this.setState({ loading: true });
            this.callHandleActivateAll();
        }

    }

    async callHandleActivateAll() {
        // await this.props.handleChangeStateActivateAll()
        // console.log("Action!!!")
        const current_page = this.props.current_page
        const ordem = this.props.ordem
        const th = this.props.th
        const qtd_per_page = this.props.qtd_per_page
        const pesquisa = this.props.pesquisa || ''
        const empresa_id = this.props.empresa_id
        // console.log(order);
        ativar_all(1, qtd_per_page, ordem, th, pesquisa, empresa_id)
            .then(resp => this.props.get(current_page, qtd_per_page, ordem, th, pesquisa, empresa_id))
            .then(resp => this.setState({ loading: false }))
    }

    async callHandleDeactivateAll() {
        // await this.props.handleChangeStateActivateAll()
        // console.log("Action!!!")
        const current_page = this.props.current_page
        const ordem = this.props.ordem
        const th = this.props.th
        const qtd_per_page = this.props.qtd_per_page
        const pesquisa = this.props.pesquisa || ''
        const empresa_id = this.props.empresa_id
        // console.log(order);
        desativar_all(1, qtd_per_page, ordem, th, pesquisa, empresa_id)
            .then(resp => this.props.get(current_page, qtd_per_page, ordem, th, pesquisa, empresa_id))
            .then(resp => this.setState({ loading: false }))
    }

    deactivateAll(e) {
        e.preventDefault();
        if (window.confirm("Deseja realmente desativar todos os registros selecionados?")) {
            this.setState({ loading: true });
            this.callHandleDeactivateAll();
        }

    }

    //Função que mapeia o objeto com as pilulas
    renderGrid() {
        const agenda_pilula = this.props.agenda_pilula || []
        return agenda_pilula.map(desc => (
            <tr key={desc.id}>
                <td style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>{desc.data_agenda}</td>
                <td style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>{desc.titulo}</td>
                <td style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>{desc.campanha}</td>
                <td style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>{desc.nome}</td>
                <td className="text-center" style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>
                    <Toggle

                        id={desc.id}
                        checked={desc.ativo === 1 ? (true) : (false)}
                        onChange={this.props.handleCheeseChange} />
                    {/* <label htmlFor='cheese-status'>Adjacent label tag</label> */}

                </td>
                {/* <td>
                    <ul className="nav">
                        <li className="nav-item dropdown">
                            <a className="nav-link" data-toggle="dropdown" href="#/">
                                <i className="fas fa-ellipsis-h" />             
                            </a>
                            <div className='dropdown-menu dropdown-menu-right'>                  
                                <Link to={`/view_pilula/${desc.id}`} className='dropdown-item'><i className='fas fa-eye'></i> Detalhes</Link>
                                <Link disabled to='#/' data-toggle='modal' className='dropdown-item'><i className='fas fa-trash'></i> Excluir</Link>
                                <Link to={`/edit_pilula/${desc.id}`} className='dropdown-item' ><i className='fas fa-edit'></i> Editar</Link>
                                <Link to={`/agenda_pilula/${desc.id}`} className='dropdown-item' ><i className='fas fa-calendar-alt'></i> Agendar</Link>                                
                            </div>
                        </li>

                    </ul>		
				</td> */}

            </tr>
        ))
    }

    render() {
        const data = 'agenda_pilula.data_agenda';
        const titulo = 'pilula.titulo';
        const campanha = 'campanha.nome';
        const usuario = 'usuario.nome'

        return (
            <div className="wrapper">
                <Header />
                <Sidebar />
                <StylePage>
                    {this.state.loading === true ? (
                        <Content>
                            <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                        </Content>

                    ) : <Table
                        view={
                            <div className="col-sm-12 col-md-5">
                                <div className="dataTables_info" id="example1_info" role="status" aria-live="polite" style={{ fontFamily: fonts.primary, fontSize: "1.1rem" }}>
                                    Exibindo de {this.props.from} à {this.props.to} do total de {this.props.qtd_total}
                                </div>
                            </div>
                        }
                        cabecalho={
                            <Cabecalho to="#/" action={this.props.get} clear={this.props.clearPesquisa} valuePesquisa={this.props.pesquisa} value={this.props.qtd_per_page} onChange={this.props.change_qtd_per_page} changePesquisa={this.props.changePesquisa} style={{ fontFamily: fonts.primary, fontSize: "1.1rem" }} />
                        }
                        pagination={<Pagination>{this.props.pagination(this.props.current_page, this.props.get, this.props.last_page)}</Pagination>} title="Pilulas">
                        <Thead>
                            <th style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>Data de Agendamento<Link to="/agendamentos" style={{ float: 'right' }} onClick={() => this.props.order(data)}><i className="text-right fas fa-sort-amount-down-alt"></i></Link></th>
                            <th style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>Título<Link to="/agendamentos" style={{ float: 'right' }} onClick={() => this.props.order(titulo)}><i className="right fas fa-sort-amount-down-alt"></i></Link></th>
                            <th style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>Campanha<Link to="/agendamentos" style={{ float: 'right' }} onClick={() => this.props.order(campanha)}><i className="right fas fa-sort-amount-down-alt"></i></Link></th>
                            <th style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>Usuário<Link to="/agendamentos" style={{ float: 'right' }} onClick={() => this.props.order(usuario)}><i className="right fas fa-sort-amount-down-alt"></i></Link></th>
                            <th className="text-center" style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-3">
                                            <Link to="#/" onClick={(e) => this.activateAll(e)} style={{ marginLeft: '15px', marginRight: '5px', maxWidth: '50px', }} className="btn btn-outline-success btn-sm"><i className="right fas fa-solid fa-check"></i></Link>
                                        </div>
                                        <div className="col-md-6 col-sm-3">
                                            <Link to="#/" onClick={(e) => this.deactivateAll(e)} style={{ marginLeft: '15px', marginRight: '15px', maxWidth: '50px', }} className="btn btn-outline-danger btn-sm"><i className="right fa fa-times"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </th>
                        </Thead>
                        <Tbody>
                            {this.renderGrid()}
                        </Tbody>
                        <Tfoot>
                            <th style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>Data de Agendamento</th>
                            <th style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>Título</th>
                            <th style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>Campanha</th>
                            <th style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>Usuário</th>
                            <th style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>Ações</th>
                        </Tfoot>
                    </Table>}

                </StylePage>


                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    agenda_pilula: state.agenda_pilula.agendas_pilulas.data,
    current_page: state.agenda_pilula.agendas_pilulas.current_page,
    last_page: state.agenda_pilula.agendas_pilulas.last_page,
    from: state.agenda_pilula.agendas_pilulas.from,
    to: state.agenda_pilula.agendas_pilulas.to,
    qtd_total: state.agenda_pilula.agendas_pilulas.total,
    qtd_per_page: state.agenda_pilula.qtd_per_page,
    empresa_id: state.auth.user.empresa_id,
    ordem: state.agenda_pilula.order,
    th: state.agenda_pilula.th,
    pesquisa: state.agenda_pilula.pesquisa || ''
});
const mapDispatchToProps = dispatch =>
    bindActionCreators({ get, pagination, order, change_qtd_per_page, changePesquisa, clearPesquisa, handleCheeseChange, handleChangeStateActivateAll, handleChangeStateDeactivateAll }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(grid_agenda_pilula)
