import styled from "styled-components";
import backgroundImage from "../../backgroundImage/pilulas_rehagro_2_mobile.png";
import backgroundImagePc from "../../backgroundImage/pilulas_rehagro.png";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    background-image: url(${backgroundImage});
  }
  @media (min-width: 800px) {
    background-image: url(${backgroundImagePc});
  }
`;

export const LogoStyle = styled.div`
  /* position:relative;
    left:50%;
    margin-left:-65px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
