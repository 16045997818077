import { auth, app } from "./Api";

const authentication = async (data) => {
  return auth.post("login", data);
};

const information = async () => {
  return app.post("me");
};

const resetPassword = async () => {
  return app.post("");
};

export { authentication, information };
