import axios from "axios";
import { baseURL } from "../../baseURL/baseURL.js";

const app = axios.create({
  baseURL,
});

app.interceptors.request.use(
  (config) => {
    return {
      ...config,
      headers: {
        ...config.headers,
        common: {
          ...config.headers.common,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    };
  },
  (error) => Promise.reject(error)
);

const getPasswordResetLink = async (email) => {
  const options = {
    method: "POST",
    url: `${baseURL}/password/reset-link`,
    data: {
      email,
    },
  };

  return axios.request(options).then((response) => {
    return response.data;
  });
};

const auth = axios.create({
  baseURL,
});

export { auth, app, getPasswordResetLink };
