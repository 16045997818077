import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {Link} from 'react-router-dom'

export default props => (
    <Modal show={props.show} onHide={props.onHide} animation={false}>
        <Modal.Header id="excluir" closeButton>
            <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body> 
             {props.content}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.cancel}>
                Cancelar
            </Button>
            <Link to={props.to} className={`btn btn-${props.typeButton}`} onClick={props.del}>
                {props.nameAction}
            </Link>
        </Modal.Footer>
    </Modal>
)