import React, { Component } from 'react'
import fonts from "../../fonts/fonts.js";
export default class footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="main-footer" style={{fontFamily: fonts.primary, fontSize: "0.9rem"}}>
                    <div className="float-right d-none d-sm-block">
                        <b style={{fontFamily: fonts.primary, fontSize: "1rem"}}>Version</b> 1.0.0
                    </div>
                    <strong>Copyright © <a href="https://rehagro.com.br/">Rehagro</a> 2022.</strong> All rights
                    reserved.
                </footer>
                {/* Control Sidebar */}
                <aside className="control-sidebar control-sidebar-dark">
                    {/* Control sidebar content goes here */}
                </aside>
                {/* /.control-sidebar */}
            </React.Fragment>
        )
    }
}
