import { app } from './Api'

const post_pilula = async (data) => {
    return app.post('pilulas',data)
}

const get_pilula = async (page,qtd,order,th,pesquisa,empresa_id) => {
    return app.get(`pilulas?page=${page}&qtd=${qtd}&order=${order}&th=${th}&pesquisa=${pesquisa}&empresa_id=${empresa_id}`)
}

const nivel_pilula = async () => {
    return app.get('nivel_pilula')
}

const categoria_pilula = async (empresa) => {
    return app.get(`categoria?empresa_id=${empresa}`)
}

const edit_pilula = async (id) => {
    return app.get(`pilulas/${id}`)
}
const put_pilula = async (data,id) => {
    return app.patch(`pilulas/${id}`,data)
}

const isAgenda = async (id) => {
    return app.get(`isagenda?pilula_id=${id}`)
}

const get_empresa = async () => {
    return app.get(`get_empresa`)
}

const compartilha_pilula = async (id,data) => {
    return app.post(`compartilha_pilula/${id}`, data)
}

export { compartilha_pilula, get_empresa, post_pilula, get_pilula, nivel_pilula, categoria_pilula, edit_pilula, isAgenda, put_pilula }