import React, { Component } from 'react'
import fonts from "../../fonts/fonts.js";

export default class select extends Component {
    render() {
        return (
            <React.Fragment>
                <label style={{fontFamily: fonts.primary, fontSize: "1rem"}}>{this.props.label}</label>
                <select 
                
                    className="form-control" 
                    onChange={this.props.onChange} 
                    name={this.props.name} 
                    style={{width: '100%', fontFamily: fonts.primary, fontSize: "1rem"}}
                    value={this.props.value}
                    disabled={this.props.disabled}
                    required={this.props.required ?? false}
                >

                    {this.props.children}
                </select>
            </React.Fragment>
        )
    }
}
