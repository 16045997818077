import {Route, Redirect} from 'react-router-dom'
import React from 'react'
import {connect} from 'react-redux'


const AuthRouter = ({component:Component, ...rest }) =>{

    return (  
        <Route
            {...rest}
            render={props => {
                let token = localStorage.getItem('token')
                    if(token){
                        
                        return <Component {...props} />
                    }
                        return <Redirect
                            to={{
                            pathname: "/login",
                            state: { from: props.location }
                            }}
                        />           
            }}
        />
    );
}
const mapStateToProps = state => {
    return{
        loggedIn:state.auth.loggedIn
        
    }
}
export default connect(mapStateToProps)(AuthRouter);