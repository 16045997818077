import React, { Component } from 'react'
import Header from '../../Header/header'
import Sidebar from '../../Sidebar/sidebar'
import Footer from '../../Footer/footer'
import Table from '../../Table/table'
import Thead from '../../Table/thead'
import Tbody from '../../Table/tbody'
import Tfoot from '../../Table/tfoot'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, order, change_qtd_per_page, changePesquisa, clearPesquisa, handleCheeseChange } from './Actions/actions'
import { pagination } from '../../Pagination/paginations'
import { Link } from 'react-router-dom'
import Pagination from '../../Table/pagination'
import Cabecalho from '../../Table/cabecalho'
import StylePage from '../../Style Page/style_page'
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import ReactLoading from 'react-loading'
import { Content } from './styleLoading'
import Alert from '../../Alerts/alert'
import ModalExcluir from '../../Modal/modal'
import { delete_user } from '../../Services/user'

class grid_users extends Component {
    state = {
        loading: false,
        modal: false,
        id: '',
        success: '',
        error: ''

    }


    componentDidMount() {
        this.props.get(1)
    }

    onChangeModal = (id) => {
        this.setState({ modal: true, id: id })
    }

    handleCloseExcluir = event => {
        this.setState({ modal: false, id: '' })
    }

    handleExcluir = (e) => {
        const current_page = this.props.current_page
        e.preventDefault();
        this.setState({ loading: true, modal: false })
        delete_user(this.state.id)
            .then(resp => { this.setState({ success: resp.data.success, error: '', loading: false }) })
            .then(resp => (setTimeout(() => {
                this.setState({ success: '' })

            }, 2000)))
            .catch(e => { this.setState({ loading: false, error: e.response.data.error, success: '', modal: false }) })
            .then(resp => (this.props.get(current_page)))
        // .then(resp => {this.setState({success:resp.data.success, error:''})})

    }


    //Função que mapeia o objeto com as pilulas
    renderGrid() {
        const users = this.props.users || []
        return users.map(desc => (
            <tr key={desc.usuario_id}>
                <td>{desc.cpf}</td>
                <td>{desc.nome_usuario}</td>
                <td>{desc.des_setor}</td>
                <td>{desc.des_cooperativa}</td>
                <td>{desc.dta_criacao}</td>
                <td className="text-center">
                    <Toggle

                        id={desc.usuario_id}
                        checked={desc.ativo === 1 ? (true) : (false)}
                        onChange={this.props.handleCheeseChange} />
                    {/* <label htmlFor='cheese-status'>Adjacent label tag</label> */}

                </td>
                <td>
                    <ul className="nav">
                        <li className="nav-item dropdown">
                            <a className="nav-link" data-toggle="dropdown" href="#/">
                                <i className="fas fa-ellipsis-h" />
                            </a>
                            <div className='dropdown-menu dropdown-menu-right'>
                                <Link to={`/users/${desc.usuario_id}/view`} className='dropdown-item'><i className='fas fa-eye'></i> Detalhes</Link>
                                {/* <Link disabled to='#/' data-toggle='modal' className='dropdown-item'><i className='fas fa-trash'></i> Excluir</Link> */}
                                <Link to={`/users/${desc.usuario_id}/edit`} className='dropdown-item' ><i className='fas fa-edit'></i> Editar</Link>
                                <Link to="#/" className='dropdown-item' onClick={() => this.onChangeModal(desc.usuario_id)} ><i className='fas fa-trash-alt'></i> Excluir</Link>
                            </div>
                        </li>

                    </ul>
                </td>

            </tr>
        ))
    }

    render() {
        const cpf = 'usuario.cpf';
        const nome = 'usuario.nome';
        const setor = 'des_setor';
        const cooperativa = 'cooperativa.des_cooperativa'
        const data = 'usuario.dt_hr_gravacao'


        return (
            <div className="wrapper">
                <Header />
                <Sidebar />
                <StylePage>
                    {this.state.loading === true ? (
                        <Content>
                            <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                        </Content>

                    ) : ('')}
                    {this.state.success ? (
                        <Alert type="success">{this.state.success}</Alert>
                    ) : ("")}
                    {this.state.error ? (
                        <Alert type="danger">{this.state.error}</Alert>
                    ) : ("")}
                    <ModalExcluir
                        show={this.state.modal}
                        onHide={this.handleCloseExcluir}
                        title="Excluir Usuário"
                        content="Tem certeza que deseja excluir o usuário ?"
                        cancel={this.handleCloseExcluir}
                        del={this.handleExcluir}
                        to="/users"
                        nameAction="Excluir"
                        typeButton="danger"
                    />
                    <div style={{ display: this.state.loading === true ? ('none') : ('block') }}>

                        <Table
                            view={
                                <div className="col-sm-12 col-md-5">
                                    <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                        Exibindo de {this.props.from} à {this.props.to} do total de {this.props.qtd_total}
                                    </div>
                                </div>
                            }
                            cabecalho={
                                <Cabecalho to="#/" action={this.props.get} clear={this.props.clearPesquisa} valuePesquisa={this.props.pesquisa} value={this.props.qtd_per_page} onChange={this.props.change_qtd_per_page} changePesquisa={this.props.changePesquisa} />
                            }
                            pagination={<Pagination>{this.props.pagination(this.props.current_page, this.props.get, this.props.last_page)}</Pagination>} title="Usuários" button={<Link to="/new_user" type="button" className="btn btn-secondary">Cadastro</Link>}>
                            <Thead>
                                <th>CPF<Link to="/users" style={{ float: 'right' }} onClick={() => this.props.order(cpf)}><i className="text-right fas fa-sort-amount-down-alt"></i></Link></th>
                                <th>NOME<Link to="/users" style={{ float: 'right' }} onClick={() => this.props.order(nome)}><i className="right fas fa-sort-amount-down-alt"></i></Link></th>
                                <th>SETOR<Link to="/users" style={{ float: 'right' }} onClick={() => this.props.order(setor)}><i className="right fas fa-sort-amount-down-alt"></i></Link></th>
                                <th>Grupo<Link to="/users" style={{ float: 'right' }} onClick={() => this.props.order(cooperativa)}><i className="right fas fa-sort-amount-down-alt"></i></Link></th>
                                <th>Data Cadastro<Link to="/users" style={{ float: 'right' }} onClick={() => this.props.order(data)}><i className="right fas fa-sort-amount-down-alt"></i></Link></th>
                                <th>Status</th>
                                <th>Ações</th>
                            </Thead>
                            <Tbody>
                                {this.renderGrid()}
                            </Tbody>
                            <Tfoot>
                                <th>CPF</th>
                                <th>NOME</th>
                                <th>SETOR</th>
                                <th>Grupo</th>
                                <th>Data Cadastro</th>
                                <th>Status</th>
                                <th>Ações</th>
                            </Tfoot>
                        </Table>
                    </div>
                </StylePage>


                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    users: state.user.users.data,
    current_page: state.user.users.current_page,
    last_page: state.user.users.last_page,
    from: state.user.users.from,
    to: state.user.users.to,
    qtd_total: state.user.users.total,
    qtd_per_page: state.user.qtd_per_page,
    pesquisa: state.user.pesquisa



});
const mapDispatchToProps = dispatch =>
    bindActionCreators({ get, pagination, order, change_qtd_per_page, changePesquisa, clearPesquisa, handleCheeseChange }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(grid_users)
