const CategoriaReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'GetCategoria_Desc':
            return{...state,desc:actions.desc,asc:actions.asc,order:actions.order,th:actions.th}

        case 'GetCategoria_Asc':
            return{...state,desc:actions.desc,asc:actions.asc,order:actions.order,th:actions.th}

        case 'qtd_per_pageCategoria':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaCategoria':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaCategoria':
            return{...state, pesquisa:actions.payload}

        case 'changeActiveCategoria':
            return{...state, check:actions.payload, id_check:actions.id_check}

        case 'get_Categoria':
            return{...state, categorias:actions.payload}
        default:
            return state;
    }
}
export default CategoriaReducer;