const UserReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'GetUser_Desc':
            return{...state,desc:actions.desc,asc:actions.asc,order:actions.order,th:actions.th}

        case 'GetUser_Asc':
            return{...state,desc:actions.desc,asc:actions.asc,order:actions.order,th:actions.th}

        case 'qtd_per_pageUser':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaUser':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaUser':
            return{...state, pesquisa:actions.payload}

        case 'changeActiveUser':
            return{...state, check:actions.payload, id_check:actions.id_check}

        case 'get_User':
            return{...state, users:actions.payload}
        default:
            return state;
    }
}
export default UserReducer;