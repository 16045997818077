import React from 'react'
import {connect} from 'react-redux'
import fonts from "../../fonts/fonts.js";
import logo from '../../backgroundImage/Logo-Rehagro-chapada-branca.png'

function header(props) {
    const handleRemove = e => {
        e.preventDefault();
        localStorage.removeItem('token')
        localStorage.removeItem('style')
       

        props.logout()
    }
    const style = localStorage.getItem('style') 
        return (
            <React.Fragment>

                <nav className={`main-header navbar navbar-expand text-sm navbar-dark navbar-${!style ?('indigo'):(style)}`} style={{backgroundColor: '#20ae61'}}>
                    {/* Left navbar links */}
                    <ul className="navbar-nav">
                        <li className="nav-item" style={{fontFamily: fonts.primary, fontSize: "1rem"}}>
                            <a className="nav-link" data-widget="pushmenu" href="#/" role="button" style={{fontFamily: fonts.primary, fontSize: "1rem"}}><i className="fas fa-bars" /></a>
                        </li>
                      
                    </ul>
               
                    {/* Right navbar links */}
                    {/* Right navbar links */}
                    <ul className="navbar-nav ml-auto">
                        {/* Messages Dropdown Menu */}
                        <li className="nav-item dropdown">
                            <a className="nav-link" data-toggle="dropdown" href="#/">
                                <img src={logo} alt="Imagem" className="mr-2" style={{height:'30px',marginTop:'-8px'}}/>
                                <span style={{fontFamily: fonts.primary, fontSize: "1rem"}}> {props.name}</span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                {/* <a href="#/" className="dropdown-item">
                                    <i className="fa fa-gear" />
                                    <span>  Meus Dados</span>
                                </a> */}
                            <div className="dropdown-divider" />
                                <a href="#/" className="dropdown-item" onClick={handleRemove}>
                                    <i className="fa fa-power-off" />
                                    <span style={{fontFamily: fonts.primary, fontSize: "1rem"}}>  Sair</span>
                                </a>
                            </div>
                        </li>
                    </ul>
                </nav>

            </React.Fragment>
        )
    }

const mapStateToProps = state => {
    return{
        loggedIn: state.auth.loggedIn,
        name:state.auth.user.nome_usuario,
        style:state.auth.user.style
        
    }
  
  };
const mapDispacthToProps = dispatch => {
    return{
        logout:() => dispatch({type:'SET_LOGOUT'})
        
        
    }
  };
  export default connect(mapStateToProps,mapDispacthToProps)(header)