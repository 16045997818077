import React, { Component } from 'react'
import NavItem from './nav-item'
// import NavItemDropdown from './nav-item-dropdown'
import fonts from "../../fonts/fonts.js";

export default class menu extends Component {
    render() {
        return (
            <div style={{height: "100vh"}}>
                <React.Fragment>
                    <NavItem to="/index" item="Home" icon="fas fa-home" style={{fontFamily: fonts.primary, fontSize: "1rem"}}/>
                    <NavItem to="/pilulas" item="Pílulas" icon="fas fa-assistive-listening-systems" style={{fontFamily: fonts.primary, fontSize: "1rem"}}/>
                    <NavItem to="/agendamentos" item="Agendamentos" icon="fas fa-assistive-listening-systems" style={{fontFamily: fonts.primary, fontSize: "1rem"}}/>
                    <NavItem to="/users" item="Usuários" icon="fas fa-user" style={{fontFamily: fonts.primary, fontSize: "1rem"}}/>
                    <NavItem to="/cooperativas" item="Grupos" icon="fas fa-users" style={{fontFamily: fonts.primary, fontSize: "1rem"}}/>
                    <NavItem to="/categorias" item="Categorias" icon="fas fa-briefcase" style={{fontFamily: fonts.primary, fontSize: "1rem"}}/>
                    <NavItem to="/relatorios" item="Relatório" icon="fas fa-clipboard" style={{fontFamily: fonts.primary, fontSize: "1rem"}}/>
                {/* <NavItemDropdown dropItem="Cadastros" icon="fas fa-copy">
                    <NavItem item="Pílulas" iconCircle="far fa-circle nav-icon"/>
                    <NavItem item="Empresas" iconCircle="far fa-circle nav-icon"/>
                    </NavItemDropdown> */}
                </React.Fragment>
            </div>
        )
    }
}
