import React, { Component } from 'react'
import Header from '../../Header/header'
import Sidebar from '../../Sidebar/sidebar'
import Footer from '../../Footer/footer'
import Table from '../../Table/table'
import Thead from '../../Table/thead'
import Tbody from '../../Table/tbody'
import Tfoot from '../../Table/tfoot'
import StylePage from '../../Style Page/style_page'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { get, order, change_qtd_per_page, changePesquisa, clearPesquisa, changeDataInicial, changeDataFinal } from './Actions/actions'
import {pagination} from '../../Pagination/paginations'
import {Link} from 'react-router-dom'
import Pagination from '../../Table/pagination'
import Cabecalho from '../../Table/cabecalho'
import Alert from '../../Alerts/alert'
import { CSVLink } from "react-csv"
import Input from '../../Forms/input'
import { get_relatorio } from '../../Services/Relatorio'
import fonts from "../../../fonts/fonts.js";



class grid_relatorio extends Component {
    state = {
        id:'', 
        success:'', 
        error:'',
        relatorios:[],
        headers:[
            {label:'Colaborador',key:'value.nome'},
            {label:'Pilula',key:'value.titulo'},
            {label:'Data Publicação',key:'value.data_agenda'},
            {label:'Data Resposta',key:'value.data_resposta'},
            {label:'Tempo de Leitura', key:'tempoLeitura'},
            {label:'Tempo de Resposta',key:'value.tempo_resposta'},
            {label:'Resultado',key:'value.resultado'}
        ]
    }
    

    componentDidMount(){
        this.props.get()
        
    }
    download = event => {
        
        get_relatorio(this.props.page,this.props.qtd_total,this.props.order_var,this.props.th_var,this.props.empresa_id,this.props.data_inicial,this.props.data_final,this.props.consultant_solo,this.props.data_atual)
        .then(resp => {
            // console.log(resp.data);
            let obj = []
            resp.data.data.map((value) => {
                let dados = {
                    value,
                    tempoLeitura: value.events ? value.events.tempoLeitura : 'Não Lido'
                }
                obj.push(dados)
            })
            this.setState({relatorios: obj})
            // debugger
            // this.setState({relatorios: resp.data.data})
            // click the CSVLink component to trigger the CSV download
        })
        .then(resp => {
            this.csvLink.link.click()
        })
        
    } 

    
   
    //Função que mapeia o objeto com as pilulas
    renderGrid(){
        const relatorios = this.props.relatorios || []
        return relatorios.map(desc=>(
            <tr key={desc.id}>
                <td>{desc.nome}</td>
                <td>{desc.titulo}</td>
                <td>{desc.data_agenda}</td>
                <td>{desc.events === null ? 'Não Lido' : desc.events.dt_hr_gravacao}</td>
                <td>{desc.data_resposta}</td>
                <td>{desc.events === null ? 'Não Lido' : `${desc.events.tempoLeitura} dias`}</td>
                <td>{`${desc.tempo_resposta} dias`}</td>
                <td className="text-center" style={{color: `${desc.resultado === 'Certo' ? ('green'):desc.resultado === 'Não Respondeu' ?('orange'):('red')}`}}>{desc.resultado === 'Errado' ?(<i class="fas fa-times"></i>): desc.resultado === 'Não Respondeu' ? (<i class="fas fa-exclamation-circle"></i>):(<i class="fas fa-chevron-down"></i>)}</td>
                         
            </tr>
        ))
    }
    
    render() {
        const colaborador = 'usuario.nome';
        const pilula = 'pilula.titulo';
        const data_publicacao = 'agenda_pilula.data_agenda';
        const data_resposta = 'agenda_pilula.data_resposta';
        const tempo_resposta = 'tempo_resposta'
        const resultado = 'resultado';
        // const leitura = 'evento_agenda.dt_hr_gravacao'
     
       
        
        return (
            <div className="wrapper">
                <Header />
                <Sidebar />
                <StylePage>

                   
                    {this.state.success ? (
                        <Alert type="success">{this.state.success}</Alert>
                    ):("")}
                    {this.state.error ? (
                        <Alert type="danger">{this.state.error}</Alert>
                    ):("")}
                    
                    <div style={{display: this.state.loading === true ? ('none'):('block')}}>

                        <Table 
                            view={
                                <div className="col-sm-12 col-md-5">
                                    <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                        Exibindo de {this.props.from} à {this.props.to} do total de {this.props.qtd_total}
                                    </div>
                                </div>
                            } 
                            cabecalho={
                                <Cabecalho buttonCsv={
                                    
                                    <React.Fragment>

                                                
                                        <button className="btn btn-outline-success btn-sm" onClick={this.download}>
                                            <span className="fas fa-file-csv" style={{fontFamily: fonts.primary, fontSize: "1.1rem"}}> Exportar CSV</span>
                                        </button>
                                        
                                        <CSVLink
                                            filename={`Relatorio-${new Date()}.csv`}
                                            headers={this.state.headers}
                                            hidden={true}
                                            separator={";"}
                                            ref={(r) => this.csvLink = r}
                                            data={this.state.relatorios || []}
                                    
                                        >
                                            <span className="fas fa-file-csv"> Exportar CSV</span>
                                        </CSVLink>
                                    </React.Fragment>

                                    
                                } to="#/" action={this.props.get} clear={this.props.clearPesquisa} valuePesquisa={this.props.pesquisa} value={this.props.qtd_per_page} onChange={this.props.change_qtd_per_page} changePesquisa={this.props.changePesquisa} display="none">
                                    

                                    <React.Fragment>
                                       
                                        <div className="col-sm-12 col-md-6 mt-2">
                                            <Input 
                                                type="date" 
                                                icon="fas fa-calendar" 
                                                label="Data Inicial"
                                                onChange={this.props.changeDataInicial}
                                                value={this.props.data_inicial}
                                               
                                                max={this.props.data_atual}
                                            />

                                        </div>
                                        <div className="col-sm-12 col-md-6 mt-2">
                                            <Input 
                                                type="date" 
                                                icon="fas fa-calendar" 
                                                label="Data Final" 
                                                onChange={this.props.changeDataFinal}
                                                value={this.props.data_final}
                                               
                                                max={this.props.data_atual}
                                            />
                                        </div>  
                                    </React.Fragment>
                                    
                                </Cabecalho>
                            }
                            pagination={<Pagination>{this.props.pagination(this.props.current_page,this.props.get,this.props.last_page)}</Pagination>} title="Relatórios"
                            >
                            <Thead>
                                <th>COLABORADOR<Link to="/relatorios" style={{float:'right'}} onClick={()=>this.props.order(colaborador)}><i className="text-right fas fa-sort-amount-down-alt"></i></Link></th>
                                <th>PILULA<Link to="/relatorios" style={{float:'right'}} onClick={()=>this.props.order(pilula)}><i className="text-right fas fa-sort-amount-down-alt"></i></Link></th>
                                <th>DATA PUBLICAÇÃO<Link to="/relatorios" style={{float:'right'}} onClick={()=>this.props.order(data_publicacao)}><i className="text-right fas fa-sort-amount-down-alt"></i></Link></th>
                                <th>DATA LEITURA</th>
                                <th>DATA RESPOSTA<Link to="/relatorios" style={{float:'right'}} onClick={()=>this.props.order(data_resposta)}><i className="text-right fas fa-sort-amount-down-alt"></i></Link></th>
                                <th>TEMPO DE LEITURA</th>
                                <th>TEMPO DE RESPOSTA<Link to="/relatorios" style={{float:'right'}} onClick={()=>this.props.order(tempo_resposta)}><i className="text-right fas fa-sort-amount-down-alt"></i></Link></th>
                                <th>RESULTADO<Link to="/relatorios" style={{float:'right'}} onClick={()=>this.props.order(resultado)}><i className="text-right fas fa-sort-amount-down-alt"></i></Link></th>

                            </Thead>
                            <Tbody>
                                {this.renderGrid()}
                            </Tbody>
                            <Tfoot>
                                <th>COLABORADOR</th>
                                <th>PILULA</th>
                                <th>DATA PUBLICAÇÃO</th>
                                <th>DATA LEITURA</th>
                                <th>DATA RESPOSTA</th>
                                <th>TEMPO DE LEITURA</th>
                                <th>TEMPO DE RESPOSTA</th>
                                <th>RESULTADO</th>
                                
                            </Tfoot>
                        </Table>        
                    </div>
                </StylePage>
                
               
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    relatorios:state.relatorio.relatorios.data,
    current_page:state.relatorio.relatorios.current_page,
    last_page:state.relatorio.relatorios.last_page,
    from:state.relatorio.relatorios.from,
    to:state.relatorio.relatorios.to,
    qtd_total:state.relatorio.relatorios.total,
    qtd_per_page:state.relatorio.qtd_per_page,
    empresa_id:state.auth.user.empresa_id,
    // id_role:state.auth.user.id_role,
    // consultants:state.solo.consultants,
    data_atual:state.relatorio.data_atual,
    data_inicial:state.relatorio.data_inicial || '',
    data_final:state.relatorio.data_final || '',
    order_var:state.relatorio.order || '',
    th_var:state.relatorio.th || '' ,
    page:state.relatorio.page || ''
    // consultant_solo:state.solo.consultant_solo

   
  
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({pagination, get, order, change_qtd_per_page, changePesquisa, clearPesquisa, changeDataInicial, changeDataFinal}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(grid_relatorio)
