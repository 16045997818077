import { get_relatorio } from '../../../Services/Relatorio'
/**
 * 
 * @param event 
 * Função que define a quantidade de itens à ser exibido por página
 */
export const qtdPerPage = event => {
    return [{
        type: 'qtdPerPageRelatorio',
        payload: event.target.value
    },
    // get(1)
    ]
}

/**
 * 
 * @param event 
 * Função que pega o valor digitado no filtro
 */
export const getChangeFiltro = event => {
    return [{
        type: 'changeFiltroRelatorio',
        payload: event.target.value
    }]
}

/**
 * 
 * @param event 
 * Função que limpa o filtro
 */
export const clearFiltro = event => {
    return [{
        type: 'ClearFiltroRelatorio',
        payload: ''
    },
    //  get(1)
    ]
}

//Função que traz os dados do banco de dados
export const get = (page) =>{
    return[(dispatch,getState)=>{
        
        
        const data_inicial = getState().relatorio.data_inicial || ''
        const data_final = getState().relatorio.data_final || ''
        const data_atual = getState().relatorio.data_atual || ''
        const empresa_id = getState().auth.user.empresa_id     
        const order = getState().relatorio.order
        const th = getState().relatorio.th
        const qtd_per_page = getState().relatorio.qtd_per_page
        get_relatorio(page,qtd_per_page,order,th,empresa_id,data_inicial,data_final,data_atual)
        .then(resp=>dispatch({type:'get_Relatorio', payload:resp.data}))

    }]
}


//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().relatorio.asc
      
        if(asc === true){
            dispatch({type:'GetRelatorio_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetRelatorio_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_Relatorio',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaRelatorio',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaRelatorio',
        payload:'',
        data_inicial:'',
        data_final:'',
        consultant_Relatorio:''
    },get(1)]
}



export const changeDataInicial = event => {
    return[{
        type:'changeDataInicialRelatorio',
        payload:event.target.value
    }]
}

export const changeDataFinal = event => {
    return[{
        type:'changeDataFinalRelatorio',
        payload:event.target.value
    }]
}

