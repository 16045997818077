const CooperativaReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'GetCooperativa_Desc':
            return{...state,desc:actions.desc,asc:actions.asc,order:actions.order,th:actions.th}

        case 'GetCooperativa_Asc':
            return{...state,desc:actions.desc,asc:actions.asc,order:actions.order,th:actions.th}

        case 'qtd_per_pageCooperativa':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaCooperativa':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaCooperativa':
            return{...state, pesquisa:actions.payload}

        case 'changeActiveCooperativa':
            return{...state, check:actions.payload, id_check:actions.id_check}

        case 'get_Cooperativa':
            return{...state, cooperativas:actions.payload}
        default:
            return state;
    }
}
export default CooperativaReducer;